import Plugin from '../plugin';

class DialogPlugin extends Plugin {

    constructor(Vue, options) {
        super(Vue, options);
    }

    _v_name() {
        return 'dialogs';
    }
    _v_init() {

    }


}

export default DialogPlugin;