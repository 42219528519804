const MetaFieldType = {
    text     : 'text',
    password : 'password',
    email    : 'email',

    parse(code) {
        let result = this[code];
        if (typeof result !== 'string') {
            throw new Error(`Wrong meta field type '${code}'`);
        }
        return result;
    }
};

export default MetaFieldType;


