import Vue from 'vue';
import core from '.';

class ResolveValue {
    constructor(options) {
        this.update(options);
    }

    update({ value, defaultValue, params } = {}) {
        if (value !== undefined) {
            this._value = value;
        }
        if (defaultValue !== undefined) {
            this._defaultValue = defaultValue;
        }
        if (params !== undefined) {
            this._params = params;
        }
        this._currentValue = this._value == null ? this._defaultValue : this._value;
    }

    get value() {
        return core.tools.resolveValue(this._currentValue);
    }
}

class ResolveTextValue extends ResolveValue {
    constructor(options) {
        super(options);
    }
    get value() {
        let value = super.value;
        return this._params == null ? value : value.format(this._params);
    }
}

class ResolvePathValue extends ResolveValue {
    constructor(options) {
        super(options);
    }
    get value() {
        let value = super.value;
        return Vue.prototype.$localization.translate(value, this._params);
    }
}

class LocalizableString {
    constructor(options) {
        this.update(options);
    }

    update(options) {
        if (typeof options === 'object') {
            let { path, defaultPath, text, defaultText, params } = options;
            if (path !== undefined || defaultPath !== undefined) {
                options = { value : path, defaultValue : defaultPath, params };
                if (this._resolveValue instanceof ResolvePathValue) {
                    this._resolveValue.update(options);
                } else {
                    this._resolveValue = new ResolvePathValue(options);
                }
            } else if (text !== undefined || defaultText !== undefined) {
                options = { value : text, defaultValue : defaultText, params };
                if (this._resolveValue instanceof ResolveTextValue) {
                    this._resolveValue.update(options);
                } else {
                    this._resolveValue = new ResolveTextValue(options);
                }
            } else if (params !== undefined && this._resolveValue) {
                this._resolveValue.update({ params });
            }
        } else {
            this._resolveValue = new ResolveTextValue({ value : options });
        }
    }

    toString() {
        return this.value;
    }

    toJSON() {
        return { value : this.value };
    }

    get value() {
        return this._resolveValue.value;
    }
    get path() {
        return this._resolveValue._value;
    }
    get text() {
        return this._resolveValue._value;
    }
}

export default LocalizableString;
