import MetaField from "./meta-field";

class MetaEntity {
    constructor(settings) {
        this.fields = [];
        for (let name in settings) {
            settings[name].name = name;
            this.fields.push(new MetaField(settings[name]));
        }
    }

    async validate(model) {
        let modelErrors = {
            $error : false
        };
        for (let i = 0; i < this.fields.length; i++) {
            let fieldErrors = await this.fields[i].validate(model);
            if (fieldErrors) {
                modelErrors.$error = true;
                modelErrors[this.fields[i].name] = fieldErrors;
            }
        }
        return modelErrors;
    }

    indexOf(name) {
        for (let i = 0; i < this.fields.length; i++) {
            if (this.fields[i].name === name) {
                return i;
            }
        }
        return -1;
    }
}

export default MetaEntity;