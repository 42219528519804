import MetaFieldValidator from '../MetaFieldValidator';

class CustomMetaFieldValidator extends MetaFieldValidator {
    constructor(field, settings = {}) {
        super(field, settings);
        let { value } = settings;
        this._validate = value;
    }

    async validateInternal(field, model, value) {
        return this._validate
            ? await this._validate(field, model, value)
            : null;
    }
}

export default CustomMetaFieldValidator;