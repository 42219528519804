<template>
    <v-layout class="fude-icon-field" :class="classes">
        <v-flex class="text-column">
            <v-text-field
                    v-model="text"
                    ref="textField"
                    readonly
                    :label="label"
                    :placeholder="placeholder"
                    :prepend-icon="prependIcon"
                    :error-messages="errorMessages"
                    :box="box"
                    :outline="outline"
                    @keyup.enter="onkeyup"
                    @blur="onblur"
                    @click="selectFile"
            />
        </v-flex>
        <v-flex class="icon-column">
            <v-hover>
                <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 18 : 6}`">
                    <v-img
                            :src="url"
                            @click="selectFile"
                            ref="image"
                            contain
                            position="center center"
                            :width="imageWidth"
                            :height="imageHeight"/>
                </v-card>
            </v-hover>
        </v-flex>
        <input type="file" ref="uploader" @change="fileSelected" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|images/*">
    </v-layout>
</template>

<script>
    export default {
        name : 'fude-icon-field',
        props : {
            value         : {},
            label         : {},
            prependIcon   : {},
            errorMessages : {},
            box           : {},
            outline       : {},
            imageWidth    : {
                default : 96
            },
            imageHeight    : {
                default : 96
            },
            hideText       : {}
        },

        computed : {
            classes() {
                return this.hideText ? 'fude-icon-field--hide-text' : '';
            },

            text() {
                if (this.value && this.value.size != null) {
                    return this.formatBytes(this.value.size);
                }
            },
            url() {
                if (this.value) {
                    if (this.value.url) {
                        return this.value.url;
                    } else {
                        return this.value.data;
                    }
                }
                return '/images/noimage.png';
            },
            placeholder() {
                return this.$t('fude.form.icon-field.placeholder');
            }
        },

        methods : {
            formatBytes(bytes, decimals) {
                if (bytes === 0) {
                    return '0 B';
                }
                let k = 1024;
                let dm = decimals <= 0 ? 0 : decimals || 2;
                let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
                let i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },

            focus() {
                this.$refs.textField.focus();
            },

            onkeyup(e) {
                this.selectFile();
            },

            onblur(e) {
                this.$emit('blur', e);
            },

            selectFile() {
                this.$refs.uploader.click();
            },

            fileSelected($event) {
                let files = $event.target.files || $event.dataTransfer.files;
                let file = files && files[0];
                if (!file) {
                    return;
                }

                let reader = new FileReader();
                reader.onload = () => {
                    this.$emit('input', {
                        file : file,
                        size : file.size,
                        data : reader.result
                    });
                    this.$emit('next');
                };
                reader.readAsDataURL(file);
            }
        }
    }
</script>

<style lang="less">
    .fude-icon-field {
        .icon-column {
            flex-grow: 0;
            .v-image {
                cursor: pointer;
            }
            .v-card {
                padding: 5px;
                margin-bottom: 27px;
            }
        }
        .text-column {
            flex-grow: 1;
            align-self: flex-end;
            margin-right: 10px;
        }
        input[type=file] {
            display: none;
        }

        &&.fude-icon-field--hide-text {
            .text-column {
                display: none;
            }
            .icon-column {
                margin: auto;
                .v-card {
                    padding: 5px;
                    margin: 0;
                }
            }
        }
    }
</style>