import Plugin       from './plugin';
import EventBus     from './event-bus';
import LocalStorage from './local-storage';
import Localization from './localization';
import RemoteData   from './remote-data';
import Sockets      from './sockets';
import Dialog       from './dialog';
import Metadata     from './metadata';
import Core         from './core';

export  default {
    LocalStorage,
    EventBus,
    Localization,
    RemoteData,
    Sockets,
    Dialog,
    Metadata,
    Core,

    init : Plugin.init
}