import MetaFieldValidator from '../MetaFieldValidator';

const EMAIL_REGEX = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

class EmailMetaFieldValidator extends MetaFieldValidator {
    constructor(field, settings) {
        super(field, settings);
    }

    async validateInternal(field, model, value) {
        return value
            ? EMAIL_REGEX.test(value)
                ? null
                : { path : 'fude.validators.email' }
            : null
    }
}

export default EmailMetaFieldValidator;