<template>
    <div class="fude-image-dialog" v-resize="onResize">
        <v-img :src="model.url" contain :max-width="windowSize.x" :max-height="windowSize.y"/>
    </div>

</template>

<script>
    const PADDING = 110;

    export default {
        name: 'fude-image-dialog',

        props : {
            model : {}
        },

        data() {
            return {
                windowSize : {}
            }
        },

        methods : {
            onResize() {
                this.windowSize = {
                    x : window.innerWidth - PADDING,
                    y : window.innerHeight - PADDING
                }
            }
        }
    }
</script>

<style lang="less">
    .fude-image-dialog {
        .v-img {
            margin: auto;
        }
    }
</style>