<template>
    <div class="fude-field v-input v-text-field v-text-field--box v-text-field--enclosed v-input--is-label-active v-input--is-dirty v-input--is-readonly theme--dark">
        <div class="v-input__prepend-outer" v-if="prependIcon">
            <div class="v-input__icon v-input__icon--prepend">
                <v-icon>{{ prependIcon }}</v-icon>
            </div>
        </div>
        <div class="v-input__control">
            <div class="v-input__slot">
                <div class="v-text-field__slot">
                    <label aria-hidden="true" class="v-label v-label--active theme--dark" style="left: 0px; right: auto; position: absolute;">
                        <slot name="label">{{ label }}</slot>
                    </label>
                    <div class="fude-field-content" :style="contentStyle">
                        <slot></slot>
                    </div>
                </div>
            </div>
            <div class="v-text-field__details">
                <div class="v-messages theme--dark">
                    <div class="v-messages__wrapper">

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--<v-input class="v-text-field v-text-field&#45;&#45;enclosed v-text-field&#45;&#45;box v-input&#45;&#45;is-label-active"
             :label="label"
             :append-icon="appendIcon"
             :prepend-icon="prependIcon"
             ref="input"
    >
        <div class="fude-field-content" :style="contentStyle">
            <slot></slot>
        </div>
    </v-input>-->

</template>

<script>
    export default {
        name: 'fude-input-field',

        props : {
            label: {},
            icon: {},
            maxHeight: {},
            appendIcon: {},
            prependIcon: {}
        },

        computed: {
            contentStyle() {
                if (this.maxHeight && !this.$vuetify.breakpoint.xsOnly) {
                    return {
                        maxHeight : this.maxHeight
                    };
                }
            }
        }
    }
</script>

<style scoped>
    .fude-field.v-text-field > .v-input__control > .v-input__slot {
        cursor: default;
    }

    .fude-field-content {
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        margin-top: 28px;
        padding-right: 12px;
    }

    .v-input__slot {
        padding-right: 0!important;
    }
</style>