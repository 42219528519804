import Vue from 'vue';

import ConfirmDialog from '../components/dialog/confirm-dialog';
import InfoDialog from '../components/dialog/info-dialog';
import ImageDialog from '../components/dialog/image-dialog';

let dialog = new Vue({

    data() {
        return {
            id : 0
        }
    },

    methods : {

        $show(modal) {
            modal.id = ++this.id;
            this.$eventBus.$emit('MODAL_SHOW', modal);
            return modal.id;
        },

        $hide(id) {
            this.$eventBus.$emit('MODAL_HIDE', id);
        },

        $confirm(message) {
            return new Promise((resolve, reject) => {
                this.$dialog.$show({
                    component : ConfirmDialog,
                    model : {
                        message
                    },
                    actions : [{
                        result : 'YES',
                        name   : this.$t('fude.dialog.actions.yes'),
                        handle : resolve
                    },{
                        result : 'CANCEL',
                        name   : this.$t('fude.dialog.actions.no'),
                        handle : reject
                    }]
                });
            });
        },

        $info(message, tittle) {
            return new Promise((resolve) => {
                this.$dialog.$show({
                    title     : tittle,
                    component : InfoDialog,
                    model : {
                        message
                    },
                    actions : [{
                        result : 'CANCEL',
                        name   : this.$t('fude.dialog.actions.ok'),
                        handle : resolve
                    }]
                });
            });
        },

        $image(url) {
            return new Promise((resolve) => {
                this.$dialog.$show({
                    title     : this.$t('fude.dialog.image.title'),
                    component : ImageDialog,
                    fullScreen : true,
                    actionsTop : true,
                    model : {
                        url
                    },
                    actions : [{
                        result : 'CLOSE',
                        name   : this.$t('fude.dialog.actions.close'),
                        handle : resolve
                    }]
                });
            });
        }
    }
});

export default dialog;