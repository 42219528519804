import Plugin from '../plugin';
import IO     from 'socket.io-client';

class SocketsPlugin extends Plugin {

    constructor(Vue, options) {
        super(Vue, options);
    }

    _v_name() {
        return 'sockets';
    }
    _v_init() {
        this._socket = IO('', {
            transports: ['websocket']
        });
    }
    _v_mixin(plugin) {
        return {
            mounted() {
                if (this.$options[plugin.name]) {
                    let events = this.$options[plugin.name].events;
                    if (events) {
                        let binds = this[`_${plugin.name}`] = {};
                        Object.keys(events).forEach((name) => {
                            let event = events[name].bind(this);
                            binds[name] = event;
                            plugin.$on(name, event);
                        });
                    }
                }
            },
            beforeDestroy() {
                let binds = this[`_${plugin.name}`];
                if (binds) {
                    Object.keys(binds).forEach((name) => plugin.$off(name, binds[name]));
                }
            },

        }
    }

    $emit(event, ...args) {
        this._socket.emit(event, ...args);
    }
    $on(event, handler) {
        this._socket.on(event, handler);
    }
    $off(event, handler) {
        this._socket.off(event, handler);
    }
}

export default SocketsPlugin;