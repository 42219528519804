<template>
    <v-btn :color="color" @click="click" :small="small" class="fude-btn-resizable" :class="btnClass" :disabled="disabled">
        <span v-if="fullStyle">{{ title }}</span>
        <img v-if="image" :src="image"/>
        <v-icon v-else-if="icon">{{ icon }}</v-icon>
    </v-btn>
</template>

<script>
    export default {
        name: 'fude-btn-resizable',

        props : {
            title : {},
            icon  : {},
            image : {},
            color : {},
            small : {
                default : false
            },
            disabled : {}
        },

        computed : {
            fullStyle() {
                return this.$vuetify.breakpoint.smAndUp;
            },

            btnClass() {
                let result = '';
                if (this.fullStyle) {
                    result = 'fude-btn-resizable--full';
                } else {
                    result = 'fude-btn-resizable--short';
                }
                if (this.image) {
                    result += ' fude-btn-resizable--image';
                }
                return result;
            }
        },

        methods : {
            click() {
                this.$emit('click');
            }
        }
    }
</script>

<style scoped>
    .fude-btn-resizable.fude-btn-resizable--full .v-icon {
        margin-left: 8px;
    }
    .fude-btn-resizable.fude-btn-resizable--full img {
        margin-left: 8px;
    }
    .fude-btn-resizable.fude-btn-resizable--short {
        min-width: auto;
    }
    .fude-btn-resizable img {
        max-width: 32px;
        max-height: 32px;
    }

    .fude-btn-resizable.fude-btn-resizable--full.fude-btn-resizable--image {
        padding: 2px 2px 2px 16px;
    }
    .fude-btn-resizable.fude-btn-resizable--short.fude-btn-resizable--image {
        padding: 2px;
    }



</style>