import MetaFieldValidator from '../MetaFieldValidator';

class RequireMetaFieldValidator extends MetaFieldValidator {
    constructor(field, settings) {
        super(field, settings);
    }

    async validateInternal(field, model, value) {
        return (value == null || value === '') ? { path : 'fude.validators.require' } : null;
    }
}

export default RequireMetaFieldValidator;