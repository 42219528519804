<template>
    <v-card class="f-data-iterator">
        <div v-if="showHeader" class="f-data-iterator__header">
            <v-toolbar dense :color="colorLocal">
                <v-toolbar-title v-if="showTitle">
                    {{ titleLocal.value }}
                </v-toolbar-title>
                <v-spacer/>
                <v-text-field
                        v-if="showSearch"
                        v-model="searchLocal"
                        hide-details
                        single-line
                        prepend-icon="mdi-magnify"
                        class="f-data-iterator__input"
                        color="no-color"
                />
            </v-toolbar>
        </div>
        <div v-if="showError" class="f-data-iterator__error">
            <v-alert value="true" type="error">
                {{ errorLocal }}
            </v-alert>
        </div>
        <div class="f-data-iterator__content">
            <slot name="rows" :rows="rowsLocal"/>
        </div>
        <div v-if="showPagination" class="f-data-iterator__footer">
            <v-pagination
                    v-model="pageLocal"
                    :length="pageCountLocal"
                    :total-visible="pageButtonCountLocal"
                    circle
            />
        </div>
    </v-card>
</template>

<script>
    export default {
        name: 'f-data-iterator',

        props : {
            header      : {},
            color       : {},
            title       : {},
            search      : {},
            pagination  : {},
            dataSource  : {},
            sort        : {},
            handleError : {}
        },

        data() {
            return {
                titleLocal    : new this.$core.LocalizableString({
                    defaultPath : 'fude.search-toolbar.title',
                    params      : [0]
                }),
                searchLocal   : '',
                rowCountLocal : 0,
                rowsLocal     : [],
                pageLocal     : 1,
                errorLocal    : null
            }
        },

        watch: {
            title : {
                handler() {
                    if (this.title) {
                        this.titleLocal.update({ path : null });
                    } else if (this.title === false) {
                        this.titleLocal.update({ path : null });
                    } else {
                        this.titleLocal.update(this.title);
                    }
                },
                immediate : true,
                deep      : true
            },
            rowCountLocal() {
                this.titleLocal.update({
                    params : [this.rowCountLocal]
                });
            },
            dataSource() {
                this.load(this.pageLocal);
            },
            pageLocal() {
                this.load(this.pageLocal);
            },
            searchLocal() {
                this.load(1);
            },
            sort() {
                this.load(this.pageLocal);
            }
        },

        computed : {
            showHeader() {
                return this.header !== false
            },

            showTitle() {
                return this.title !== false
            },

            colorLocal() {
                return this.color || this.$consts.fude.searchPanel.headerColor;
            },

            showSearch() {
                return this.search !== false;
            },

            showError() {
                return !!this.errorLocal;
            },

            showPagination() {
                return this.pagination !== false;
            },

            rowsPerPageLocal() {
                return this.pagination || this.$consts.fude.dataIterator.rowsPerPage;
            },

            pageButtonCountLocal() {
                return this.$vuetify.breakpoint.name === 'xs' ? 5 : 7;
            },

            pageCountLocal() {
                return this.showPagination ? Math.ceil(this.rowCountLocal / this.rowsPerPageLocal) : 0;
            }
        },

        methods : {
            async load(page) {
                this.errorLocal = null;
                this.pageLocal = page;
                let sort;
                let desc;
                switch (typeof this.sort) {
                    case 'string' :
                        sort = this.sort;
                        desc = false;
                        break;
                    case 'object' :
                        let keys = Object.keys(this.sort);
                        if (keys.length > 0) {
                            sort = keys[0];
                            desc = !!this.sort[sort];
                        }
                        break;
                }
                try {
                    let { rows, count } = await this.dataSource.load({
                        count : this.rowsPerPageLocal,
                        page  : this.pageLocal - 1,
                        text  : this.searchLocal,
                        sort,
                        desc
                    });
                    this.rowCountLocal = count;
                    this.rowsLocal = rows;
                } catch (error) {
                    this.errorLocal = (this.handleError ? this.handleError(error) : error).toString();
                }
            }
        },

        mounted() {
            this.load(1);
        }
    }
</script>

<style lang="less">
    @import "../../../styles/variables";
    .f-data-iterator {
        display: flex!important;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        .f-data-iterator__header {
            display: flex;
            flex-grow: 0;
            .f-data-iterator__input {
                padding-top: 0;
                flex-grow: 0;
            }
        }
        .f-data-iterator__error {
            display: flex;
            flex-grow: 0;
            .v-alert {
                width: 100%;
            }
        }
        .f-data-iterator__content {
            display: flex;
            flex-grow: 1;
        }
        .f-data-iterator__footer {
            display: flex;
            flex-grow: 0;
            align-self: center;
            padding: @component-padding;
        }
    }
</style>