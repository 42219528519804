import MetaEntity from './MetaEntity';
import entityHash from './entityHash';

function createEntity(options) {
    if (!options) {
        return null;
    }
    if (options instanceof MetaEntity) {
        return options;
    }
    if (typeof options === 'string') {
        options = entityHash.get(options);
    }
    return new MetaEntity(options);
}

export default createEntity;