import MetaFieldValidator from '../MetaFieldValidator';
import core from '../../core';

class LengthMetaFieldValidator extends MetaFieldValidator {
    constructor(field, settings = {}) {
        super(field, settings);
        let { min, max, equal } = settings;
        this._min   = min;
        this._max   = max;
        this._equal = equal;
    }

    async validateInternal(field, model, value) {
        if (!value) {
            return null;
        }
        let length = value.length || 0;
        let min   = (this._min   != null && length <   this._min);
        let max   = (this._max   != null && length >   this._max);
        let equal = (this._equal != null && length !== this._equal);
        let cases = core.tools.getBitString({
            min : min || (max && this._max),
            max : max || (min && this._min),
            equal
        });
        switch (cases) {
            case 'min.max.equal':
            case 'min.___.equal':
            case '___.max.equal':
            case '___.___.equal':
                return { path : 'fude.validators.length.equal',   params: [length, this._equal] };
            case 'min.___._____':
                return { path : 'fude.validators.length.min',     params: [length, this._min] };
            case '___.max._____':
                return { path : 'fude.validators.length.max',     params: [length, this._max] };
            case 'min.max._____':
                return { path : 'fude.validators.length.min-max', params: [length, this._min, this._max] };
            default:
                return false;
        }
    }
}

export default LengthMetaFieldValidator;