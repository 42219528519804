<template>
    <v-form @submit.prevent="" :style="style">
        <fude-form-field-proxy
                v-for="field in meta.fields"
                :key="field.name"
                :field="field"
                :model="model"
                @next="next"
                ref="fields"
        ></fude-form-field-proxy>
        <v-alert v-if="error" type="error" :value="true" style="width: 100%">
            {{ error }}
        </v-alert>

        <v-layout>
            <v-flex>
                <slot></slot>
            </v-flex>
        </v-layout>

    </v-form>
</template>

<script>
    import MetaEntity from '../../../fude-web/metadata/meta-entity';
    import FudeFormFieldProxy from './form-field-proxy';

    export default {
        name : 'fude-form',

        props : {
            model    : {},
            settings : {},
            error    : {}
        },

        components : {
            FudeFormFieldProxy
        },

        data() {
            return {
                meta : { fields : null }
            }
        },

        computed : {
            style() {
                return {
                    display       : 'flex',
                    flexDirection : (this.settings && this.settings.row)
                        ? 'row'
                        : 'column'
                }
            }
        },

        watch : {
            'settings.fields' : {
                handler() {
                    this.buildMeta()
                },
                immediate : true
            }
        },

        methods : {
            async next(field) {
                let index = this.meta.fields.indexOf(field) + 1;
                if (index < this.$refs.fields.length) {
                    this.$refs.fields[index].focus();
                } else if(!await this.hasErrors()) {
                    this.$emit('last-field-enter');
                }
            },

            async hasErrors(ignoreNotEdit) {
                let firstError = null;
                for (let i = 0; i < this.meta.fields.length; i++) {
                    let errors = await this.$refs.fields[i].validate(ignoreNotEdit);
                    if (!firstError && errors && errors.length) {
                        firstError = this.meta.fields[i].name;
                    }
                }
                if (firstError) {
                    this.setFocus(firstError);
                }
                return !!firstError;
            },

            setFocus(index) {
                switch (typeof index) {
                    case 'function' :
                        index = index(this.model);
                    case 'string':
                        index = this.meta.indexOf(index);
                }
                if (index >= 0 && index < this.$refs.fields.length) {
                    this.$refs.fields[index].focus();
                }
            },

            buildMeta() {
                let meta = null;
                if (this.settings && this.settings.fields) {
                    meta = new MetaEntity(this.settings.fields);

                    //TODO Перенести установку свойств по умолчанию в MetaEntity (продумать реактивность)
                    meta.fields.forEach((f) => {
                        if (this.model[f.name] == null) {
                            this.$set(this.model, f.name, null);
                        }
                    });

                    this.$nextTick(() => this.setFocus(this.settings.defaultFocus || 0));
                } else {
                    meta = { fields : null }
                }
                this.meta = meta;
            }
        },

        eventBus : {
            CURRENT_LOCALE_CHANGED() {
                this.buildMeta();
            }
        }
    }
</script>