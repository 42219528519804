export default function debounce (callback, limit) {
    let timeout = null;
    return function () {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(function () {
            timeout = null;
            callback.call();
        }, limit);
    }
}