<template>
    <h3 class="f-info-dialog">
        <div v-for="line in lines">{{ line }}</div>
    </h3>
</template>

<script>
    export default {
        name: 'f-info-dialog',
        props : {
            model : {}
        },
        computed: {
            lines() {
                return this.model.message
                    ? this.model.message.split('\n')
                    : [];
            }
        }
    }
</script>

<style lang="less">
    .f-info-dialog {
        max-width: 500px;
    }
</style>