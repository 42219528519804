import Plugin from '../plugin';
import core from '../../core';

class CorePlugin extends Plugin {

    constructor(Vue, options) {
        super(Vue, options);
    }

    _v_name() {
        return 'core';
    }
    _v_prototype(plugin) {
        return core;
    }
}

export default CorePlugin;