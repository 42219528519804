import metadata from '.';
import core from '../core';
import MetaEntityErrors from './MetaEntityErrors';

class MetaEntity {
    constructor({
        name,
        fields
    } = {}) {
        this.name = name;
        this.fields = core.tools.extractOptionsArray(fields, 'name', 'label').map((options) => {
            let field = metadata.createField(this, options);
            return this[field.name] = field;
        });
    }

    async validate(model, errors = null) {
        if (!errors) {
            errors = new MetaEntityErrors(this);
        }
        errors.clear();
        for (let i = 0; i < this.fields.length; i++) {
            await this.fields[i].validate(model, errors);
        }
        return errors;
    }

    createErrors() {
        return new MetaEntityErrors(this);
    }
}

export default MetaEntity;