class DataSource {
     constructor({  } = {}) {
         this.loading = false;

     }

     async load({ sort, desc, count, page, text } = {}) {
        return await this._v_loadInternal(sort, desc, count, page, text);
    }

     async _v_loadInternal(sort, desc, count, page, text) {
        return {
            rows: [],
            count: 0
        }
    }
}

export default DataSource;