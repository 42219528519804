import metadata from '.';
import core from '../core';

class MetaField {
    constructor(entity, {
        name,
        type,
        label,
        placeholder,
        icon,
        readonly,
        sortable,
        validate
    } = {}) {
        this.entity      = entity;
        this.name        = name;
        this.type        = metadata.MetaFieldType.parse(type);
        this.label       = new core.LocalizableString(label);
        this.placeholder = new core.LocalizableString(placeholder);
        this.icon        = icon;
        this.readonly    = readonly;
        this.sortable    = sortable;
        this.validators  = [];

        let defaultValidators = this._v_defaultValidators();
        if (defaultValidators) {
            this.validators.push(...defaultValidators);
        }
        core.tools.extractOptionsArray(validate, 'type').forEach((validatorOptions) => {
            let validator = metadata.createValidator(this, validatorOptions);
            if (validator.type === metadata.MetaFieldValidatorType.trim) {
                this.validators.unshift(validator);
            } else {
                this.validators.push(validator);
            }
        });
    }

    async validate(model, errors) {
        for (let i = 0; i < this.validators.length; i++) {
            errors.push(this, await this.validators[i].validate(model));
        };
    }

    _v_defaultValidators() {
        return null;
    }

    get value() {
        return this.name;
    }
}

export default MetaField;