<template functional>
    <div class="f-panel f-panel--inner-title">
        <v-card class="f-panel__border">
            <div v-if="props.title" class="f-panel__title">
                {{ props.title }}
            </div>
            <slot></slot>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: 'f-panel',

        props : {
            title : {
                type : String
            }
        }
    }
</script>

<style lang="less">
    @import '../styles/variables';
    .f-panel {
        height: 100%;
        .f-panel__title {
            position: absolute;
            z-index: 100;
            top: -@component-padding;
        }
        .f-panel__border {
            height: 100%;
            padding: 4px @component-padding @component-padding;
        }
        &&.f-panel--inner-title {
            .f-panel__title {
                margin-left: 12px;
                position: initial;
            }
        }
    }
</style>