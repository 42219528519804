class NullableArray {

    constructor() {
        this._items = [];
    }

    push(...items) {
        for (let i = 0; i < items.length; i++) {
            if (items[i]) {
                this._items.push(items[i]);
            }
        }
    }
    unshift(...items) {
        for (let i = 0; i < items.length; i++) {
            if (items[i]) {
                this._items.unshift(items[i]);
            }
        }
    }
    toArray() {
        return this._items.length ? this._items : null;
    }

    get isEmpty() {
        return !this._items.length;
    }
}

export default NullableArray;