function getSettings(settings, defaultMessage) {
    let result;
    switch (typeof (settings)) {
        case 'string' : result = { message : settings }; break;
        case 'object' : result = settings;               break;
        default       : result = { value : settings };   break;
    }
    if (!result.message) {
        result.message = defaultMessage;
    }
    return result;
}

function builder(defaultMessage, validateInternal) {
    return (settings) => {
        settings = getSettings(settings, defaultMessage);
        return async (value, model) => {
            if (await validateInternal(settings, value, model)) {
                return null;
            }
            return typeof settings.message == 'function'
                ? settings.message(settings, value)
                : settings.message;
        }
    }
}

import required from './required';
import maxLength from './max-length';
import equalLength from './equal-length';
import email from './email';

export default {
    required    : builder((s, v) => 'Должно быть заполнено',          required),
    maxLength   : builder((s, v) => `Длинна не должна превышать ${s.value}`, maxLength),
    equalLength : builder((s, v) => `Длинна должна быть ${s.value}`, equalLength),
    email       : builder((s, v) => `Неверная электронная почта`,     email),

    custom : (validate) => {
        return async (value, model) => {
            return await validate(value, model);
        }
    }
}