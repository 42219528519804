class UrlParser {
    constructor(url) {
        url = (url || '').trim().toLowerCase();
        let index = url.indexOf('#');
        if (index >= 0) {
            this.path = url.substr(0, index).trim();
            this.hash = url.substr(index + 1).trim();
        } else {
            this.path = url;
            this.hash = '';
        }
        this.path = this.normalizeSlashes(this.path);
        this.hash = this.normalizeSlashes(this.hash);
    }

    normalizeSlashes(str) {
        if (!str.length) {
            return '/';
        }
        if (str.length == 1 && str[0] == '/') {
            return str;
        }
        if (str[0] != '/') {
            str = '/' + str;
        }
        if (str[str.length - 1] != '/') {
            str = str + '/';
        }
        return str;
    }

    toUrl() {
        return `${this.path}#${this.hash}`;
    }
}

function getCurrent() {
    let origin = window.location.origin;
    if (!origin) {
        origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
    }
    let url = window.location.href.replace(origin, '');
    return new UrlParser(url);
}


let Location = {
    methods : {
        $navigate(url) {
            let current = getCurrent();
            let next = new UrlParser(url);

            if (current.path == next.path) {
                if (current.hash == next.hash) {
                    this.$reload();
                } else {
                    this.$router.push(next.hash);
                }
            } else {
                window.location.href = next.toUrl();
            }
        },

        $reload() {
            window.location.reload();
        }
    }
}

export default Location;