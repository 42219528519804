if (!String.prototype.format) {
    String.prototype.format = format;
}

function format(...args) {
    let result = this;
    if (args.length) {
        let params = typeof args[0] === 'object' ? args[0] : args;
        for (let name in params) {
            result = result.replace(new RegExp(`\\{${name}\\}`, 'g'), params[name]);
        }
    }
    return result;
}