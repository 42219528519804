<template>
    <f-search-panel
        :filter.sync="filter"
        :title="title"
    >
        <v-data-table
                :headers="headers"
                :items="rows"
                :pagination.sync="pagination"
                :total-items="100"
                header-key="name"

                hide-actions
                must-sort
        >
            <template slot="headerCell" slot-scope="{ header }">
                <span>
                  {{ header.label.value }}
                </span>
            </template>

            <template slot="items" slot-scope="{ item }">
                <td v-for="header in headers">
                    <slot :name="header.name" :item="item">
                        {{ item[header.name] }}
                    </slot>
                </td>
            </template>

        </v-data-table>

        <pre style="padding: 10px">{{ JSON.stringify(this.pagination, null, 4) }}</pre>



    </f-search-panel>
</template>

<script>
    export default {
        name: 'f-data-table',
        props: {
            options: {
                type: Object
            }
        },

        data() {
            return {
                pagination: {

                },
                filter: {
                    searchText : '',
                    page : 1,
                    descending: false,
                    rowsPerPage: 20,
                    sortBy: null,
                    totalItems: 3
                }
            }
        },

        computed: {
            title() {
                if (!this.options) {
                    return null;
                }
                return {
                    path : this.options.title,
                    params: [this.rows.length]
                };
            },
            headers() {
                let entity = this.$metadata.createEntity(this.options && this.options.entity);
                if (entity) {
                    return entity.fields.map((field) => field);
                } else {
                    return [];
                }
            },
            rows() {
                return this.options.dataSource.load().rows;
            }
        },

        mounted() {


        }
    }
</script>