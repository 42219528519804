import MetaFieldValidatorType from './MetaFieldValidatorType';
import TrimMetaFieldValidator from './validators/TrimMetaFieldValidator';
import RequireMetaFieldValidator from './validators/RequireMetaFieldValidator';
import LengthMetaFieldValidator from './validators/LengthMetaFieldValidator';
import EmailMetaFieldValidator from './validators/EmailMetaFieldValidator';
import CustomMetaFieldValidator from './validators/CustomMetaFieldValidator';

function createValidator(field, options = {}) {
    let type = MetaFieldValidatorType.parse(options.type);
    switch (type) {
        case MetaFieldValidatorType.trim: {
            return new TrimMetaFieldValidator(field, options);
        }
        case MetaFieldValidatorType.require: {
            return new RequireMetaFieldValidator(field, options);
        }
        case MetaFieldValidatorType.length: {
            return new LengthMetaFieldValidator(field, options);
        }
        case MetaFieldValidatorType.email: {
            return new EmailMetaFieldValidator(field, options);
        }
        case MetaFieldValidatorType.custom: {
            return new CustomMetaFieldValidator(field, options);
        }
    }
}

export default createValidator;