import TextMetaField from './TextMetaField';
import MetaFieldValidatorType from "../MetaFieldValidatorType";
import createValidator from "../createValidator";

class EmailMetaField extends TextMetaField {
    constructor(entity, options = {}) {
        super(entity, options);
    }

    _v_defaultValidators() {
        return [
            createValidator(this, { type : MetaFieldValidatorType.trim }),
            createValidator(this, { type : MetaFieldValidatorType.email })
        ];
    }
}

export default EmailMetaField;