class MetaFieldErrors {
    constructor({ name } = {}) {
        this.name = name;
        this._errors = [];
    }

    push(error) {
        if (error) {
            this._errors.push(error);
        }
    }
    clear() {
        this._errors = [];
    }

    get messages() {
        return this._errors.map((error) => error.toString());
    }
    get isEmpty() {
        return !this._errors.length;
    }
}

export default MetaFieldErrors;