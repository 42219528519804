<template>
    <div class="dialog-container">
        <fude-dialog v-for="dialog in dialogs" :key="dialog.id" :dialog="dialog"
                     @close="close(dialog.id)"
                     @startClose="startClose"
        />
    </div>
</template>

<script>
    import FudeDialog from "./dialog";

    export default {
        components: { FudeDialog },
        name: "fude-dialog-container",

        data : function() {
            return {
                dialogs : []
            }
        },

        methods : {
            getDialogIndex(id) {
                for (let i = 0; i < this.dialogs.length; i++) {
                    if (this.dialogs[i].id === id) {
                        return i;
                    }
                }
                return -1;
            },

            close(id) {
                let index = this.getDialogIndex(id);
                if (index >= 0) {
                    this.dialogs.splice(index, 1);
                }
                this.setActive();
            },

            startClose() {
                let fullScreen = this.$vuetify.breakpoint.xsOnly;
                for (let i = 0; i < this.dialogs.length; i++) {
                    if (this.dialogs[i].fullScreen) {
                        fullScreen = true;
                    }
                }
                if (this.dialogs.length > 1) {
                    this.htmlScroll(!fullScreen);
                } else {
                    this.htmlScroll(null);
                }
            },

            setActive() {
                let fullScreen = this.$vuetify.breakpoint.xsOnly;
                for (let i = 0; i < this.dialogs.length; i++) {
                    this.dialogs[i].active = false;
                    if (this.dialogs[i].fullScreen) {
                        fullScreen = true;
                    }
                }
                let last = this.dialogs[this.dialogs.length - 1];
                if (last) {
                    last.active = true;
                    this.htmlScroll(!fullScreen);
                } else {
                    this.htmlScroll(null);
                }
            },

            htmlScroll(value) {
                document.documentElement.classList.remove('overflow-y-hidden-override');
                document.documentElement.classList.remove('overflow-y-scroll-override');
                if (value == null) {
                    return;
                }
                if (value) {
                    document.documentElement.classList.add('overflow-y-scroll-override');
                } else {
                    document.documentElement.classList.add('overflow-y-hidden-override');
                }
            }
        },

        eventBus : {
            MODAL_SHOW({ id, title, fullScreen, autoWidth, component, model, actions, actionsTop, wideContainer, onShow, onHide }) {
                if (actions) {
                    actions.forEach((a) => a.loading = false);
                }
                this.dialogs.push({
                    id,
                    title,
                    fullScreen,
                    autoWidth,
                    component,
                    model,
                    actions,
                    actionsTop,
                    wideContainer,
                    onShow,
                    onHide,
                    active : false
                });
                this.setActive();
            },
            MODAL_HIDE(id) {
                this.close(id);
            }
        }
    }
</script>

<style>
    .overflow-y-hidden-override {
        /*overflow-y: hidden!important;*/
    }
    .overflow-y-scroll-override {
        /*overflow-y: scroll!important;*/
    }
</style>