import Validators from './validators';

function getValidators(settings) {
    let result = [];
    if (settings) {
        for (let name in settings) {
            result.push(Validators[name](settings[name]));
        }
    }
    return result;
}

class MetaField {
    constructor(settings) {
        this.type       = settings.type;
        this.name       = settings.name;
        this.label      = settings.label ? ((typeof settings.label === 'string') ? settings.label : settings.label()) : settings.name;
        this.title      = settings.title ? ((typeof settings.title === 'string') ? settings.title : settings.title()) : null;
        this.icon       = settings.icon;
        this.trim       = settings.trim;
        this.readonly   = settings.readonly;
        this.items      = settings.items;
        this.validators = getValidators(settings.validate);
    }

    async validate(model) {
        if (this.trim) {
            model[this.name] = (model[this.name] || '').trim();
        }
        let value = model[this.name];
        let errors = [];
        for (let i = 0; i < this.validators.length; i++) {
            let error = await this.validators[i](value, model);
            if (error) {
                errors.push(error);
            }
        }
        return errors.length ? errors : null;
    }
}

export default MetaField;
