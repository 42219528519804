import MetaFieldType from './MetaFieldType';
import TextMetaField from './fields/TextMetaField';
import PasswordMetaField from './fields/PasswordMetaField';
import EmailMetaField from './fields/EmailMetaField';

const DEFAULT_TYPE = MetaFieldType.text;

function createField(entity, options = {}) {
    if (!options.type) {
        options.type = DEFAULT_TYPE;
    }
    switch (options.type) {
        case MetaFieldType.password:
            return new PasswordMetaField(entity, options);
        case MetaFieldType.email:
            return new EmailMetaField(entity, options);
        case MetaFieldType.text:
            return new TextMetaField(entity, options);
    }
}

export default createField;