function extractOptionsArray(options, nameField = 'name', valueField = 'value') {
    if (Array.isArray(options)) {
        return options;
    }
    let result = [];
    if (typeof options === 'object') {
        let keys = Object.keys(options);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = options[key];
            if (typeof value === 'object') {
                value[nameField] = key;
                result.push(value);
            } else {
                let option = {};
                option[nameField] = key;
                option[valueField] = value;
                result.push(option);
            }
        }
    }
    return result;
}

export default extractOptionsArray;