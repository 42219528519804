class Enum {
    constructor(items) {
        Object.keys(items).forEach((name) => {
            this[name] = items[name];
        });
    }
    getValue(name) {
        name = (name || '').toLowerCase();
        name = Object.keys(this).find((n) => n.toLowerCase() === name);
        return this[name];
    }
    getName(value) {
        return Object.keys(this).find((name) => this[name] === value);
    }
    getNameValuePairs() {
        return Object.keys(this).map((name) => ({ name, value : this[name] }));
    }
}

export default Enum;