import core from '../core';
import MetaFieldErrors from './MetaFieldErrors';

class MetaEntityErrors {
    constructor({
        fields
    } = {}) {
        this.fields = core.tools.extractOptionsArray(fields, 'name').map((options) => {
            let field = new MetaFieldErrors(options);
            return this[field.name] = field;
        });
    }

    push(field, error) {
        let fieldErrors = this[field.name];
        if (!fieldErrors) {
            fieldErrors = new MetaFieldErrors(field);
            this.fields.push(this[fieldErrors.name] = fieldErrors);
        }
        fieldErrors.push(error);
    }

    clear() {
        this.fields.forEach((field) => field.clear());
    }

    isEmpty() {
        for (let i = 0; i < this.fields.length; i++) {
            if (!this.fields[i].isEmpty) {
                return false;
            }
        }
        return true;
    }
}

export default MetaEntityErrors;