import metadata from '.';
import core from '../core';

class MetaFieldValidator {
    constructor(field, {
        type
    } = {}) {
        this.field = field;
        this.type  = metadata.MetaFieldValidatorType.parse(type)
    }

    async validate(model) {
        if (!model) {
            return null;
        }
        let value = model[this.field.name];
        let result = await this.validateInternal(this.field, model, value);
        return result && new core.LocalizableString(result);
    }

    async validateInternal(field, model, value) {}
}

export default MetaFieldValidator;