<template>
    <v-card class="f-image-field" @click="selectFile">
        <v-img
                :src="url"
                :max-width="maxWidth"
                :max-height="maxHeight"
                contain
                class="f-image-field__image"
        />
        <input class="f-image-field__file" type="file" ref="uploader" @change="fileSelected" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|images/*">
    </v-card>
</template>

<script>
    export default {
        name : 'f-image-field',
        props : {
            model : {},
            imageField : {
                type    : String,
                default : 'image'
            },
            maxWidth : {
                type : String
            },
            maxHeight : {
                type : String
            }
        },

        computed : {
            url() {
                let image = this.model && this.model[this.imageField];
                return image && (image.data || image.url);
            }
        },

        methods : {
            formatBytes(bytes, decimals) {
                if (bytes === 0) {
                    return '0 B';
                }
                let k = 1024;
                let dm = decimals <= 0 ? 0 : decimals || 2;
                let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
                let i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },

            selectFile() {
                this.$refs.uploader.click();
            },

            fileSelected($event) {
                let files = $event.target.files || $event.dataTransfer.files;
                let file = files && files[0];
                if (!file) {
                    return;
                }

                let reader = new FileReader();
                reader.onload = () => {
                    this.model[this.imageField] = {
                        file : file,
                        size : file.size,
                        data : reader.result
                    };
                };
                reader.readAsDataURL(file);
            }
        }
    }
</script>

<style lang="less">
    @import "../../../styles/variables";

    .f-image-field {
        padding: @component-padding;
        cursor: pointer;
        .f-image-field__image {
            margin: auto;
        }
        .f-image-field__file {
            display: none;
        }
    }
</style>