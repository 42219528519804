import Plugin from '../plugin';
import RemoteData from './remote-data';

class RemoteDataPlugin extends Plugin {

    constructor(Vue, options) {
        super(Vue, options);
    }

    _v_name() {
        return 'remoteData';
    }
    _v_mixin(plugin) {
        return {
            beforeCreate() {
                if (this.$options[plugin.name]) {
                    this.$remoteData = new RemoteData(plugin, this, this.$options[plugin.name]);
                }
            },
            created() {
                if (this.$remoteData instanceof RemoteData) {
                    this.$remoteData.load().catch(Plugin.noop);
                }
            }
        }
    }

    async load(handler, vm = null) {
        if (!handler) {
            return null;
        }
        if (typeof handler === 'function') {
            let handlerResult = vm ? handler.apply(vm) : handler();
            if (typeof handlerResult === 'string') {
                let res = await this.$http.get(handlerResult);
                return res.data;
            } else if (handlerResult instanceof Promise) {
                return await handlerResult;
            } else {
                return handlerResult;
            }
        } else {
            let res = await this.$http.get(handler);
            return res.data;
        }
    }
}

export default RemoteDataPlugin;