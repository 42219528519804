import Vue from 'vue';

const ENTER = 13;

export default {
    name : 'fude-form-field-proxy',

    props : {
        field : {},
        model : {}
    },

    data() {
        return {
            errors  : null,
            changed : false
        }
    },

    methods : {
        async validate(ignoreNotEdit) {
            if (ignoreNotEdit || this.changed) {
                this.errors = await this.field.validate(this.model);
            }
            return this.errors;
        },

        focus() {
            this.$refs.element.focus()
        }
    },

    computed : {
        fieldValue() {
            return this.model[this.field.name];
        },
        fieldLabel() {
            return this.field.label;
        },
        fieldPlaceholder() {
            return this.$t(this.field.title || 'fude.form.text-field.placeholder');
        },
        fieldIcon() {
            return this.field.icon;
        }
    },

    render(h) {
        
        let self = this;

        let elementName;

        let props = {
            value         : this.fieldValue,
            label         : this.fieldLabel,
            placeholder   : this.fieldPlaceholder,
            prependIcon   : this.fieldIcon,
            errorMessages : this.errors,
            box           : true,
            outline       : false,
            readonly      : this.field.readonly
        };

        switch (this.field.type) {
            case 'text':
            case 'password':
                props.type  = this.field.type;
                elementName = 'v-text-field';
                break;
            case 'icon':
                elementName = 'fude-icon-field';
                break;
            case 'select' :
                props.items = this.field.items || [];
                elementName = 'v-select';
                break;
        }

        return h(elementName, {
            props,
            attrs : {},
            on : {
                async next() {
                    await self.validate();
                    self.$emit('next', self.field);
                },
                async keyup(event) {
                    if (event.keyCode !== ENTER) {
                        return;
                    }
                    self.changed = true;
                    await self.validate();
                    self.$emit('next', self.field);
                },
                async blur() {
                    await self.validate();
                },
                async input(value) {
                    Vue.set(self.model, self.field.name, value);
                    self.changed = true;
                    await self.validate();
                }
            },
            ref : 'element'
        })
    }
};