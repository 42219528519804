<template>
    <h3>
        <div v-for="line in lines">{{ line }}</div>
    </h3>
</template>

<script>
    export default {
        name  : 'fude-confirm-dialog',
        props : {
            model : {}
        },

        computed: {
            lines() {
                return this.model.message
                    ? this.model.message.split('\n')
                    : [];
            }
        }
    }
</script>