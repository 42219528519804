import MetaFieldValidator from '../MetaFieldValidator';

class TrimMetaFieldValidator extends MetaFieldValidator {
    constructor(field, settings) {
        super(field, settings);
    }

    async validateInternal(field, model, value) {
        if (value != null) {
            model[field.name] = value.trim();
        }
        return false;
    }
}

export default TrimMetaFieldValidator;