import DataSource from '../DataSource';
import { orderBy } from 'lodash';

class LocalDataSource extends DataSource {
    constructor(rows = [], searchFields = ['name']){
        super();
        this._rows = rows;
        this._searchFields = searchFields;
    }

     async _v_loadInternal(sort, desc, count, page, text) {
         let limit = count;
         let offset = (limit && page != null) ? (page * limit) : null;

         let rows = sort
            ? orderBy(this._rows, sort, desc ? 'desc' : 'asc')
            : [...this._rows];

        if (text) {
            text = text.toLowerCase();
            rows = rows.filter((r) => {
                for (let i = 0; i < this._searchFields.length; i++) {
                    let value = r[this._searchFields[i]];
                    if (value == null) {
                        value = '';
                    } else if (typeof value !== 'string') {
                        value = value.toString();
                    }
                    if (value.toLowerCase().indexOf(text) >= 0) {
                        return true;
                    }
                }
                return false;
            });
        }
        let findCount = rows.length;
        if (limit) {
            if (!offset) {
                offset = 0;
            }
            rows = rows.slice(offset, offset + limit);
        }

        throw new Error('qwerty');

        return {
            rows,
            count : findCount
        }
    }
}

export default LocalDataSource;

