export default {
    fude : {
        dataIterator : {
            headerColor : 'primary',
            rowsPerPage : 25,
        },

        searchPanel : {
            headerColor : 'primary'
        }
    }
}