import core from '../../core';

function getBitString(cases) {
    if (typeof cases === 'object') {
        let keys = Object.keys(cases);
        return keys.map((key) => {
            let value = core.tools.resolveValue(cases[key]);
            return value ? key : core.tools.repeatSequence('_', key.length);
        }).join('.');
    }
    return null;
}

export default getBitString;