const MetaFieldValidatorType = {
    trim    : 'trim',
    require : 'require',
    length  : 'length',
    email   : 'email',
    custom  : 'custom',

    parse(code) {
        return this[code] || this.custom;
    }
};

export default MetaFieldValidatorType;


