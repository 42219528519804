import Plugin  from '../plugin';
import VueI18n from 'vue-i18n';
import moment  from 'moment';

const DEFAULT_LOCALE            = 'en';
const DEFAULT_TARGET            = 'app';
const DEFAULT_LOCALE_URL        = '/localization/locales/{code}/{target}';
const STORAGE_FIELD             = 'current_locale';
const LOCALE_HTTP_HEADER        = 'locale-id';
const CURRENT_LOCALE_CHANGED    = 'CURRENT_LOCALE_CHANGED';
const DEFAULT_DATE_FORMAT       = 'DD.MM.YYYY HH:mm:ss';
const DEFAULT_DATE_FORMAT_SHORT = 'DD.MM.YYYY';

class LocalizationPlugin extends Plugin {
    constructor(Vue, options) {
        super(Vue, options);

        this.Vue.use(VueI18n);

        this._url             = this.options.url             || DEFAULT_LOCALE_URL;
        this._target          = this.options.target          || DEFAULT_TARGET;
        this._dateFormat      = this.options.dateFormat      || DEFAULT_DATE_FORMAT;
        this._dateFormatShort = this.options.dateFormatShort || DEFAULT_DATE_FORMAT_SHORT;
        this._disabled        = this.options.disabled;

        this._i18n   = new VueI18n({
            locale         : this.options.current || DEFAULT_LOCALE,
            fallbackLocale : DEFAULT_LOCALE
        });

        this._loading = null;
    }

    _v_name() {
        return 'localization';
    }
    async _v_init() {
        await this._loadLocale(this._i18n.locale, this._target);
        let locale = this.$localStorage.get(STORAGE_FIELD) || this.current;
        await this.set(locale);
    }
    _v_methods(plugin) {
        return {
            $t  : (key, ...params)             => plugin.translate(key, ...params),
            $td : (key, defaultKey, ...params) => plugin.translateDefault(key, defaultKey, ...params)
        }
    }

    async set(locale) {
        this._loading = locale;
        await this._loadLocale(locale, this._target);
        if (this._loading === locale) {
            this.$localStorage.set(STORAGE_FIELD, locale);
            this._i18n.locale = locale;
            this.$http.defaults.headers.common[LOCALE_HTTP_HEADER] = this.current_id;
            this.$eventBus.$emit(CURRENT_LOCALE_CHANGED);
        }
    }
    translate(key, ...params) {
        if (key instanceof Date) {
            if (params && params[0] === true) {
                return moment(key).format(this._dateFormatShort);
            } else {
                return moment(key).format(this._dateFormat);
            }
        } else if (key) {
            return this._i18n.t(key, ...params);
        } else {
            return null;
        }
    }
    translateDefault(key, defaultKey, ...params) {
        if (key) {
            return (typeof key === 'string')
                ? this.translate(key, params)
                : this.translate(defaultKey, params);
        }
        return null;
    }

    get current() {
        return this._i18n.locale;
    }
    get current_id() {
        let messages = this._i18n.messages[this.current];
        return messages.__locale__;
    }

    get default() {
        return DEFAULT_LOCALE;
    }
    get default_id() {
        let messages = this._i18n.messages[this.default];
        return messages.__locale__;
    }

    async _loadLocale(code, target) {
        let loaded = Object.keys(this._i18n.messages);
        if (!loaded.includes(code)) {
            if (this._disabled === true) {
                this._i18n.setLocaleMessage(code, {
                    __locale__ : code
                });
            } else {
                let url = this._url.format({ code, target });
                let res = await this.$http.get(url);
                this._i18n.setLocaleMessage(code, res.data);
            }
        }
    }
}

export default LocalizationPlugin;