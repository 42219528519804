<template>
    <v-card class="f-search-panel">
        <v-layout row wrap>
            <v-flex xs12 class="pt-0">
                <v-toolbar
                        dense
                        :color="colorLocal"
                >
                    <v-toolbar-title v-if="showTitle">{{ titleLocal.value }}</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                            v-model="filter.searchText"
                            hide-details
                            single-line
                            prepend-icon="mdi-magnify"
                            class="f-search-panel__input"
                            color="no-color"
                    />
                </v-toolbar>
            </v-flex>
            <v-flex xs12>
                <slot/>
            </v-flex>
            <v-flex xs12 class="text-xs-center pa-2">
                <v-pagination
                        v-model="filter.page"
                        :length="filter.length"
                        :total-visible="7"
                        circle
                />
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
    export default {
        name: 'f-search-panel',

        props: {
            filter : {},
            color  : {},
            title  : {}
        },

        data() {
            return {
                titleLocal      : new this.$core.LocalizableString({
                    defaultPath : 'fude.search-toolbar.title',
                    params      : [0]
                })
            }
        },

        watch: {
            title: {
                handler() {
                    if (this.title == null) {
                        this.titleLocal.update({ path : null });
                    } else if (this.title === false) {
                        this.titleLocal.update({ path : null });
                    } else {
                        this.titleLocal.update(this.title);
                    }
                },
                immediate: true,
                deep: true
            }
        },

        computed: {
            showTitle() {
                return this.title !== false
            },

            colorLocal() {
                return this.color || this.$consts.fude.searchPanel.headerColor;
            }
        }
    }
</script>

<style lang="less">
    .f-search-panel {
        display: flex!important;
        height: 100%;
        .f-search-panel__input {
            padding-top: 0;
        }
    }

</style>