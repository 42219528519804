<template>
    <v-alert v-if="error" color="error" icon="mdi-alert-circle" value="true" class="fude-error-alert">
        {{ error }}
    </v-alert>
</template>

<script>
    export default {
        name : 'fude-error-alert',

        props : ['error']
    }
</script>

<style>
    .fude-error-alert {
        width: 100%;
    }
</style>