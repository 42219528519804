import Plugin from '../plugin';

class EventBusPlugin extends Plugin {
    constructor(Vue, options) {
        super(Vue, options);
        this._vue = new Vue({});
    }

    _v_name() {
        return 'eventBus';
    }
    _v_mixin(plugin) {
        return {
            mounted() {
                if (this.$options[plugin.name]) {
                    let events = this.$options[plugin.name];
                    if (events) {
                        let binds = this[`_${plugin.name}`] = {};
                        Object.keys(events).forEach((name) => {
                            let event = events[name].bind(this);
                            binds[name] = event;
                            plugin.$on(name, event);
                        });
                    }
                }
            },
            beforeDestroy() {
                let binds = this[`_${plugin.name}`];
                if (binds) {
                    Object.keys(binds).forEach((name) => plugin.$off(name, binds[name]));
                }
            }
        }
    }

    $emit(event, ...args) {
        this._vue.$emit(event, ...args);
    }
    $on(event, handler) {
        this._vue.$on(event, handler);
    }
    $off(event, handler) {
        this._vue.$off(event, handler);
    }
}

export default EventBusPlugin;