<template>
    <fude-input-field class="fude-field-translation-list"
        :label="label"
        :prepend-icon="icon"
        :max-height="maxHeight"
    >
        <div v-for="item in items" :key="item.locale_id" class="fude-field-translation-list-item">
            <img
                    :src="item.locale_icon_url"
                    :title="item.locale_name"
            />
            <v-text-field
                    v-if="item.edit"
                    v-model="item.value"
                    outline
                    class="small-input"
                    color="white"
                    ref="inputs"
                    @keyup.enter="endEdit(item)"
                    @blur="endEdit(item)"
            />
            <div
                    v-else
                    @click="beginEdit(item)"
                    class="fude-field-translation-list-item--caption"
            >
                {{ item.value }}
            </div>
        </div>
    </fude-input-field>
</template>

<script>
    export default {
        name: 'fude-field-translation-list',

        props : {
            label : {},
            icon : {},
            maxHeight : {},
            valueField : {
                default : 'value'
            },
            value : {
                default(){
                    return [];
                }
            },
        },

        data() {
            return {
                items: []
            }
        },

        watch: {
            value: {
                handler() {
                    this.setItems();
                },
                immediate : true
            }
        },

        methods: {
            beginEdit(item) {
                this.items.forEach((item) => item.edit = false);
                item.edit = true;
                this.$nextTick(() => {
                    let input = this.$refs.inputs && this.$refs.inputs[0];
                    if (input) {
                        input.focus();
                    }
                });
            },
            endEdit(item) {
                item.edit = false;
                let translations = this.items
                    .filter((item) => !!item.value)
                    .map((item) => {
                        return {
                            locale_id         : item.locale_id,
                            [this.valueField] : item.value
                        };
                    });
                this.$emit('input', translations);
            },
            setItems() {
                this.items = this.$context.locales.map((locale) => {
                    let translation = this.value && this.value.find((t) => t.locale_id === locale.id);
                    return {
                        locale_icon_url : locale.icon.url,
                        locale_name     : locale.name,
                        locale_id       : locale.id,
                        value           : translation && translation[this.valueField],
                        edit            : false
                    }
                });
            }
        }
    }
</script>

<style>
    .fude-field-translation-list-item {
        display: flex;
        margin: 10px 0;
    }
    .fude-field-translation-list-item img {
        max-width: 32px;
        max-height: 32px;
        display: flex;
        margin-right: 8px;
        align-self: center;
        cursor: pointer;
    }

    .fude-field-translation-list-item--caption {
        background-color: #424242;
        border-radius: 4px;
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        padding: 0 12px;
        height: 36px;
        border: 2px solid #424242;
    }

    .fude-field-translation-list-item .v-input__control {
        height: 36px;
    }


    .fude-field-translation-list-item--caption:hover {
        border: 2px solid white;
    }


    .fude-field-translation-list .v-input input {
        margin-top: 0;
    }
    .fude-field-translation-list .v-messages,
    .fude-field-translation-list .v-input__slot,
    .fude-field-translation-list .v-input__control {
        min-height: 0;
        margin-bottom: 0;
    }
    .fude-field-translation-list .v-text-field__details {
        margin-bottom: 0;
    }
</style>