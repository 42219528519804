import metadata from '../../metadata';
import Plugin from '../plugin';
import SearchPanel from "./components/SearchPanel";
import DataIterator from './components/DataIterator';
import DataTable from './components/DataTable';

class MetadataPlugin extends Plugin {

    constructor(Vue, options) {
        super(Vue, options);
    }

    _v_name() {
        return 'metadata';
    }

    _v_components(plugin) {
        return [
            DataIterator,
            SearchPanel,
            DataTable
        ];
    }

    _v_prototype(plugin) {
        return metadata;
    }
}

export default MetadataPlugin;